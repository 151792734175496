<script setup lang="ts" name="Sidebar">
import { computed } from 'vue';

import {
  useDevFeaturesStore,
  useOrganisationsStore,
  useProfileStore,
} from '~/store';

import { useNavBarLinks } from '~/features/useNavBarLinks';
import { hasOneOfPermissions } from '~/features/usePermissions';
import { getHomeRouteBasedOnUser } from '~/features/useRouteHelpers';

import Icon from '~/components/Icon.vue';
import LogoG2OVertical from '~/components/LogoG2OVertical.vue';
import NavBarLink from '~/components/NavBarLink.vue';
import NavBarOrganisationSelect from '~/components/NavBarOrganisationSelect.vue';
import SidebarLayout from '~/components/SidebarLayout.vue';
import SidebarToggle from '~/components/SidebarToggle.vue';

const { isOpen } = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  close: [];
}>();

const organisation = computed(() => useOrganisationsStore().organisation);

const activeLinks = computed(() =>
  useNavBarLinks({
    cargoNominationIsEnabled: useDevFeaturesStore().CARGO_NOMINATION,
    waitingForBerthEnabled: useDevFeaturesStore().STATISTICS_WAITING_FOR_BERTH,
    isG2OEmployee: useProfileStore().isG2OEmployee,
    canViewUserList: hasOneOfPermissions(['user.view.any'], organisation.value),
    organisation: organisation.value,
  }),
);

const logoLink = computed(() => {
  if (organisation.value) {
    return {
      name: 'OrganisationHomeIndex',
      params: { organisationId: organisation.value.id },
    };
  }

  if (useProfileStore().isG2OEmployee) {
    return { name: 'AdminOrganisationsIndex' };
  }

  return {};
});
</script>

<template>
  <SidebarLayout :open="isOpen">
    <div class="flex h-16 w-full items-center">
      <RouterLink :to="logoLink">
        <LogoG2OVertical class="ml-4" />
      </RouterLink>
      <button
        class="ml-auto flex h-8 w-8 items-center justify-center rounded-full hover:bg-primary-6 lg:hidden"
        @click.stop="emit('close')"
      >
        <Icon icon="ic:round-close" />
      </button>
    </div>

    <div data-test="organisation-switcher" class="mx-2 mt-4">
      <NavBarOrganisationSelect @close="emit('close')" />
    </div>

    <nav data-test="nav-sidebar" class="mx-2 my-4 space-y-[9px]">
      <NavBarLink
        v-for="link in activeLinks"
        v-bind="link"
        :key="link.dataTest"
        @close="emit('close')"
      />
    </nav>

    <div class="flex flex-1 flex-col justify-end">
      <SidebarToggle />
    </div>
  </SidebarLayout>
</template>
